/* styles.css */
#gradient-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    --gradient-color-1: #0400ff;
    --gradient-color-2: #00bd8e;
    --gradient-color-3: #ff4747;
    --gradient-color-4: #160142;
}